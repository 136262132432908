import React from 'react'


import {MDBContainer, MDBCarousel, MDBCarouselItem, MDBCarouselCaption,  MDBCard,
    MDBCardImage,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBRow,
    MDBCol } from 'mdb-react-ui-kit';
    import Navmain from './header';
import Footer from './footer';
import Appdev from './img/banners/appdev.jpg';


function appdev() {
  return (
    <>
    <Navmain />

    <div>
    <MDBContainer>
    <MDBCardImage
            src={Appdev}
            alt='...'
            position='top'
            style={{margin:'10px 0 0 0'}}
          />
          </MDBContainer>
    </div>

    <div className='mt-6'>
    <MDBContainer>
      <div className='clearfix' style={{display:'flex', margin:'80px 0 80px 0'}}>
      {/* <svg style={{margin:'0px 20px 0 0'}}
          className='bd-placeholder-img col-md-6 mb-3 ms-md-3'
          width='100%'
          height='210'
          xmlns='http://www.w3.org/2000/svg'
          role='img'
          aria-label='Placeholder: Responsive floated image'
          preserveAspectRatio='xMidYMid slice'
          focusable={false}
        >
          <title>Placeholder</title>
          <rect width='100%' height='100%' fill='#868e96'></rect>
          <text x='50%' y='50%' fill='#dee2e6' dy='.3em'>
            Responsive floated image
          </text>
        </svg> */}


        <div >
          <h3>App Development</h3>

        
        <p>
        In pursuit of providing value-added high quality IT solutions to our clients in selected horizontal and vertical segments, by combining technology skills, domain expertise, process focus and a commitment to long-term client relationships, AIJN Infotech was founded by highly energetic, experienced time-tested professionals.
        </p>
        <p>
        In pursuit of providing value-added high quality IT solutions to our clients in selected horizontal and vertical segments, by combining technology skills, domain expertise, process focus and a commitment to long-term client relationships, AIJN Infotech was founded by highly energetic, experienced time-tested professionals.
        </p>
        <p>
        <button type="button" class="btn btn-info" data-mdb-ripple-init>Read More</button>
        </p>

        </div> 
      </div>
    </MDBContainer>
    </div>



    <Footer/>
    </>
  )
}

export default appdev