import React from 'react'

import {MDBContainer, MDBCarousel, MDBCarouselItem, MDBCarouselCaption,  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBRow,
  MDBCol } from 'mdb-react-ui-kit';
import Navmain from './header';
import Footer from './footer';
import Webdevelopment from './img/banners/webdev.jpg';
import App from './img/banners/appdev.jpg';
import Maint from './img/banners/webmntn.jpg';
import Digital from './img/banners/digimark.jpg';
import Design from './img/banners/gphcdsg.jpg';
import About from './img/aboutus.png';
import homeImg from './img/banners/homebanner.jpg';


function home() {
  return (
    
    <>
    <Navmain />

<div>
    <MDBContainer 
    height='30%' >
    <MDBCardImage
            
            src={homeImg}
            alt='...'
            position='top'
            style={{ margin: '10px 0 0 0' }}/>
        
        
    </MDBContainer>
    </div>
    
    


    

<div className='mt-5'>







<MDBContainer>


  <div style={{margin:'50px 0 0 0'}}>
    {/* <h1 className=''>Services</h1>text-center */}
    <p className='text-center mb-6 mt-6 text-decoration-underline'>
      <h1>Services</h1>
    </p>
  </div>
<MDBRow className='row-cols-1 row-cols-md-3 g-4'>
      <MDBCol>
        <MDBCard>
          <MDBCardImage
            src={Webdevelopment}
            alt='...'
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle>Website Development</MDBCardTitle>
            <MDBCardText>
            Developing a website involves creating engaging, informative, and user-friendly material that aligns with your brand and resonates with your target audience. Here are some key considerations for website content development
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard>
          <MDBCardImage
            src={App}
            alt='...'
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle>App Development</MDBCardTitle>
            <MDBCardText>
            App development website involves showcasing your expertise, services, and the value you bring to potential clients.
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard>
          <MDBCardImage
            src={Maint}
            alt='...'
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle>Website Maintenance</MDBCardTitle>
            <MDBCardText>
            Website development involves various aspects, from planning and design to coding and maintenance. Here's an overview of key topics related to website development.
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard>
          <MDBCardImage
            src={Digital}
            alt='...'
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle>Digital Marketing</MDBCardTitle>
            <MDBCardText>
            A Digital marketing website involves conveying your expertise in online promotion, lead generation, and brand visibility
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>

      <MDBCol>
        <MDBCard>
          <MDBCardImage
            src={Design}
            alt='...'
            position='top'
          />
          <MDBCardBody>
            <MDBCardTitle>Graphic Design</MDBCardTitle>
            <MDBCardText>
            A Graphic design website involves showcasing your creativity, skills, and the visual appeal of your work.
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>

    </MDBRow>
    </MDBContainer>
</div>

    <Footer/>
    </>
  )
}

export default home
