import React from 'react';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './home';
import Navmain from './header';
import Footer from './footer';
import Aboutus from './aboutus';
import Privacy from './privacy';
import WebDev from './webdev';
import Appdev from './appdev';
import WebMntn from './webmntn';
import Digitalmrk from './dgtlmark';
import Graphicdesign from './graphicdesign';
import Contact from './contact';


function App() {
  return (
   <>
   <BrowserRouter>
        <Routes>
        <Route path='/' index element={<Home />} />
        <Route path='/header' index element={<Navmain />} />
        <Route path='/footer' index element={<Footer/>} />
        <Route path='/aboutus' index element={<Aboutus/>}/>
        <Route path='/websitedevelopment' index element={<WebDev/>}/>
        <Route path='/appde' index element={<Appdev/>}/>
        <Route path='/websitem' index element={<WebMntn/>}/>
        <Route path='/digitalmrk' index element={<Digitalmrk/>}/>
        <Route path='/graphicdesign' index element={<Graphicdesign/>}/>
        <Route path='/contact' index element={<Contact/>}/>
        <Route path='/privacy' index element={<Privacy/>}/>
        </Routes>
        </BrowserRouter>
   </>
  );
}

export default App;
