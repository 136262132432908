import React from 'react';

import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';

import footer_img from './img/home/footer.jpg';

import homeCSS from './style/home.css'

const sectionStyle = {
  backgroundImage:`url(${footer_img})`
  // backgroundColor: 'gray'
  
};

function footer() {

 

  return (
    <div >

      {/* <div style={sectionStyle}>
        
      </div> */}

{/* style={{ backgroundImage:`url(${footer_img})` }} */}
    <MDBFooter  style={{ backgroundImage:`url(${footer_img})` }} className= 'text-center  text-lg-start text-muted'>
      {/* <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
        <div className='me-5 d-none d-lg-block'>
          <span>Get connected with us on social networks:</span>
        </div>

        <div>
          <a href='' className='me-4 text-reset'>
            <MDBIcon fab icon="facebook-f" />
          </a>
          <a href='' className='me-4 text-reset'>
            <MDBIcon fab icon="twitter" />
          </a>
          <a href='' className='me-4 text-reset'>
            <MDBIcon fab icon="google" />
          </a>
          <a href='' className='me-4 text-reset'>
            <MDBIcon fab icon="instagram" />
          </a>
          <a href='' className='me-4 text-reset'>
            <MDBIcon fab icon="linkedin" />
          </a>
          <a href='' className='me-4 text-reset'>
            <MDBIcon fab icon="github" />
          </a>
        </div>
      </section> */}

      <section style={{color:"white", padding:"5px", margin:"5px"}}>
        <MDBContainer  className='text-center text-md-start mt-5'>
          <MDBRow className='mt-3' >
            <MDBCol md="3" lg="4" xl="3" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold '>
                {/* <MDBIcon icon="gem" className="me-3" /> */}
                AIJN INFOTECH
              </h6>
              <p>
              In pursuit of providing value-added high quality IT solutions to our clients in selected horizontal and vertical segments, by combining technology skills, domain expertise, process focus and a commitment to long-term client relationships, AIJN INFOTECH was founded by highly energetic, experienced time-tested professionals.
              </p>
            </MDBCol>

          

            <MDBCol md="4" lg="3" xl="3" className='mx-auto mb-md-0 mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Contact</h6>
              <p>
                <MDBIcon icon="home" className="me-2" />
                Madhapur, Hyderabad, India
              </p>
              <p>
                <MDBIcon icon="envelope" className="me-3" />
                aijninfotech@gmail.com
              </p>
              <p>
                <MDBIcon icon="phone" className="me-3" /> +91 9392813814
              </p>
              
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      {/* <div className='text-center p-4' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
        © 2024 Copyright:
        <a className='text-reset fw-bold' href='#'>
         &nbsp; AIJN Infotech
        </a>
      </div> */}
    </MDBFooter>
    
    </div>
    
    
  )
}



export default footer
