import React from 'react'

import {
    MDBContainer, MDBCarousel, MDBCarouselItem, MDBCarouselCaption,  MDBCard,
    MDBCardImage,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBRow,
    MDBCol,
    MDBInput,
    MDBCheckbox,
    MDBBtn,
    MDBIcon
  } from 'mdb-react-ui-kit';

  import Navmain from './header';
import Footer from './footer';
import Aboutmain from './img/banners/contact.jpg';

function contact() {
  return (
   <>
   <Navmain />

   <MDBContainer>
    <MDBCardImage
            src={Aboutmain}
            alt='...'
            position='top'
            style={{margin:'10px 0 0 0'}}
          />
          </MDBContainer>

          <MDBContainer>
            <MDBRow>
            <MDBCol md="6" lg="6" xl="6">
<MDBCol md="12" lg="12" xl="12" className='emailhead'>
    <MDBRow className='per'>
<MDBCol md="4" lg="4" xl="4">

<div className='email'>
    <div style={{fontSize:'40px'}}>
    <MDBIcon far icon="envelope" />
    </div>
    <div style={{fontWeight:'bold', fontSize:'18px'}}>
    E-mail Address
    </div>
    <div>aijn.help92@example.com</div>
</div>

</MDBCol>
<MDBCol md="4" lg="4" xl="4">
<div className='email'>
    <div style={{fontSize:'40px'}}>
    <MDBIcon fas icon="headphones-alt" />
    </div>
    <div style={{fontWeight:'bold', fontSize:'18px'}}>
    Phone Number
    </div>
    <div>+91 9392813814</div>
     
</div>


</MDBCol>
<MDBCol md="4" lg="4" xl="4">

<div className='email'>
    <div style={{fontSize:'40px'}}>
    <MDBIcon fas icon="map-marker-alt" />
    </div>
    <div style={{fontWeight:'bold', fontSize:'18px'}}>
    Address
    </div>
    <div style={{textAlign:'center'}}>Yet to be Updated</div>
</div>
</MDBCol>
</MDBRow>
</MDBCol>

            </MDBCol>
            <MDBCol md="6" lg="6" xl="6">

            <form style={{margin:'100px 0 0 0'}}>
      <MDBInput id='form4Example1' wrapperClass='mb-4' label='Name' />
      <MDBInput type='email' id='form4Example2' wrapperClass='mb-4' label='Email address' />
      <MDBInput wrapperClass='mb-4' textarea id='form4Example3' rows={4} label='Message' />

      <MDBCheckbox
        wrapperClass='d-flex justify-content-center mb-4'
        id='form4Example4'
        label='Send me a copy of this message'
        defaultChecked
      />

      <MDBBtn type='submit' className='mb-4' block>
        Sign in
      </MDBBtn>
    </form>
            </MDBCol>
   
    </MDBRow>
    </MDBContainer>
    <Footer/>
   </>
  )
}

export default contact;
